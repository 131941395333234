import { Container } from "@mui/material"
import { Outlet } from "react-router-dom"

import NavMenu from "core/components/NavBar"

const Layout = () => {
  return (
    <div>
      <NavMenu />
      <Container>
        <Outlet />
      </Container>
    </div>
  )
}

export default Layout
