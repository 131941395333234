import { Fragment } from 'react'

import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import CustomerLogo, { CustomLogo } from 'core/components/CustomerLogo'
import Header from 'core/components/molecules/Header'

import { addMissedProtocol, capitalize, getRidOfProtocol } from 'core/helpers/string'

import Button from 'mui/Button'
import Page from 'mui/Page'

type CustomLogoType = {
  id: number
  imageData: string
  customerId: number
  customer: string
}

export type LocalCustomerType = {
  id: number
  addedDateTime: string
  name: string
  domain: string
  deploymentUrl: string
  apiKey: string
  numberContributorsAllowed: number
  enabled: boolean
  user: string
  customLogo?: CustomLogoType
}

export type FormValues = Modify<
  Pick<LocalCustomerType, 'name' | 'domain' | 'deploymentUrl' | 'apiKey' | 'numberContributorsAllowed' | 'enabled'>,
  { enabled: number }
>

type CustomerProfileProps = {
  onEditClick: () => void
  onDeleteClick: () => void
} & Partial<Omit<LocalCustomerType, 'id' | 'customLogo'>>

const useStyles = makeStyles()((theme) => ({
  content: {
    marginBottom: theme.spacing(1),
    wordWrap: 'break-word'
  },
  subtitle: {
    color: 'grey'
  },
  box: {
    padding: theme.spacing(1),
    maxWidth: '250px'
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer'
  }
}))

const CustomerProfile = (props: CustomerProfileProps & { customLogoImageData?: string }) => {
  const { classes } = useStyles()
  const {
    name = '',
    domain = '',
    deploymentUrl = '',
    addedDateTime = '',
    apiKey = '',
    numberContributorsAllowed = 0,
    enabled = false,
    user = '',
    customLogoImageData,
    onEditClick,
    onDeleteClick
  } = props

  const dataRows = [
    {
      title: 'name',
      content: name
    },
    {
      title: 'domain',
      content: (
        <Link href={addMissedProtocol(domain)} underline="hover" target="_blank" rel="noopener noreferrer">
          {domain}
        </Link>
      )
    },
    {
      title: 'deployment URL',
      content: (
        <Link href={addMissedProtocol(deploymentUrl)} underline="hover" target="_blank" rel="noopener noreferrer">
          {deploymentUrl}
        </Link>
      )
    },
    {
      title: 'enabled',
      content: String(enabled)
    },
    {
      title: 'number of contributers allowed',
      content: numberContributorsAllowed
    },
    {
      title: 'API key',
      content: apiKey
    },
    {
      title: 'added by',
      content: user
    },
    {
      title: 'added date',
      content: addedDateTime
    }
  ]

  return (
    <Page>
      <Box p={2} width="250px" textAlign="center" role="presentation">
        <Box mb={2}>{customLogoImageData ? <CustomLogo imageData={customLogoImageData} /> : null}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'center' }}>
          <CustomerLogo url={getRidOfProtocol(domain)} size="large" />
          <Header>{name}</Header>
        </Box>
      </Box>
      <Box className={classes.box}>
        {dataRows.map(({ title, content }) => (
          <Fragment key={title}>
            <Typography classes={{ root: classes.subtitle }}>{capitalize(title)}</Typography>
            <Typography classes={{ root: classes.content }}>{content}</Typography>
          </Fragment>
        ))}
      </Box>

      <Button
        classes={{ root: classes.content }}
        variant="outlined"
        onClick={onEditClick}
        color="primary"
        startIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 14 }} />}
      >
        edit customer
      </Button>
      <Button
        classes={{ root: classes.content }}
        variant="outlined"
        onClick={onDeleteClick}
        color="error"
        startIcon={<FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: 14 }} />}
      >
        delete customer
      </Button>
    </Page>
  )
}

export default CustomerProfile
