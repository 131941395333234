import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from 'App'
import config from 'config'
import AppTheme from 'mui/theme'

import './index.css'

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') || ''
const rootElement = document.getElementById('root')

const root = rootElement ? ReactDOM.createRoot(rootElement) : null

const RootEl = (
  <BrowserRouter basename={baseUrl}>
    <ThemeProvider theme={AppTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
)

;(async () => {
  const settings = await config()
  if (!settings) {
    root?.render(RootEl)
    return
  }

  const pca = new PublicClientApplication(settings.msalConfiguration)

  const AppProvider = () => <MsalProvider instance={pca}>{RootEl}</MsalProvider>
  root?.render(<AppProvider />)
})()

// registerServiceWorker()
