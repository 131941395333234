import { TextField, Typography, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { UseMutateFunction } from '@tanstack/react-query'
import { useForm, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { FormValues } from 'core/components/CustomerProfile'
import Header from 'core/components/molecules/Header'
import URLTextField from 'core/components/molecules/UrlTextField'

import { addMissedProtocol, capitalize, getRidOfProtocol, validDomain, validUrl } from 'core/helpers/string'

import Button from 'mui/Button'
import Page from 'mui/Page'

type CustomerFormProps = {
  title: string
  save: UseMutateFunction<Response, unknown, FormValues, unknown>
  loading: boolean
  initialValues?: FormValues
  onClose: () => void
}

const useStyles = makeStyles()((theme) => ({
  editTextField: {
    marginBottom: theme.spacing(2)
  }
}))

const CustomerForm = ({ title, save, onClose, loading, initialValues }: CustomerFormProps) => {
  const { classes } = useStyles()

  const defaultEnabledValue = !initialValues || initialValues?.enabled ? 1 : 0

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    control
  } = useForm<Modify<FormValues, { enabled: number }>>({
    mode: 'onChange',
    defaultValues: {
      ...initialValues,
      domain: initialValues?.domain ? getRidOfProtocol(initialValues.domain) : '',
      deploymentUrl: initialValues?.deploymentUrl ? getRidOfProtocol(initialValues.deploymentUrl) : '',
      enabled: defaultEnabledValue
    }
  })

  const watchName = useWatch({ control, name: 'name', defaultValue: initialValues?.name || '' })

  const submit = async (data: FormValues) => {
    await save({ ...data, deploymentUrl: addMissedProtocol(data.deploymentUrl) })
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Page>
        <Box p={2} width="250px" textAlign="center" role="presentation">
          <Header>{watchName || 'Customer name'}</Header>
          <Typography color="text.secondary">{title}</Typography>
        </Box>

        {[
          {
            component: TextField,
            label: 'name',
            placeholder: 'enter name',
            formAttr: register('name', { required: true })
          },
          {
            component: TextField,
            label: 'domain',
            placeholder: 'enter domain',
            formAttr: register('domain', {
              validate: { checkUrl: (value) => validDomain(value) || 'Invalid domain' },
              required: 'Domain is required'
            })
          },
          {
            component: URLTextField,
            label: 'deployment URL',
            placeholder: 'enter deployment URL',
            formAttr: register('deploymentUrl', {
              validate: { checkUrl: (value) => validUrl(value) || 'Invalid URL' },
              required: 'Deployment URL is required'
            }),
            error: !!errors.deploymentUrl,
            helperText: errors.deploymentUrl && errors.deploymentUrl.message
          }
        ].map(({ component: Component, formAttr, label, placeholder, ...rest }) => (
          <Component
            {...formAttr}
            key={formAttr.name}
            classes={{ root: classes.editTextField }}
            size="small"
            fullWidth
            label={capitalize(label)}
            placeholder={capitalize(placeholder)}
            {...rest}
          />
        ))}
        <FormControl classes={{ root: classes.editTextField }} fullWidth size="small">
          <InputLabel>{capitalize('enabled')}</InputLabel>
          <Select label={capitalize('enabled')} defaultValue={defaultEnabledValue} {...register('enabled', { required: true })} disabled={loading}>
            <MenuItem value={1}>true</MenuItem>
            <MenuItem value={0}>false</MenuItem>
          </Select>
        </FormControl>

        {[
          {
            component: TextField,
            label: 'number of contributors allowed',
            placeholder: 'enter number',
            formAttr: register('numberContributorsAllowed', {
              required: true,
              pattern: {
                value: /^[0-9]*$/, //eslint-disable-line
                message: 'Invalid Number'
              }
            }),
            error: !!errors.numberContributorsAllowed,
            helperText: errors.numberContributorsAllowed && errors.numberContributorsAllowed.message
          },
          {
            component: TextField,
            label: 'API key',
            placeholder: 'enter API key',
            formAttr: register('apiKey', { required: true }),
            type: 'password'
          }
        ].map(({ component: Component, formAttr, label, placeholder, ...rest }) => (
          <Component
            {...formAttr}
            key={formAttr.name}
            classes={{ root: classes.editTextField }}
            size="small"
            fullWidth
            label={capitalize(label)}
            placeholder={capitalize(placeholder)}
            {...rest}
          />
        ))}

        <Button classes={{ root: classes.editTextField }} type="submit" disabled={!isDirty || !isValid}>
          Submit
        </Button>
        <Button classes={{ root: classes.editTextField }} onClick={onClose}>
          Cancel
        </Button>
      </Page>
    </form>
  )
}

export default CustomerForm
