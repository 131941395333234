import { ChangeEvent, ComponentProps, ReactElement, useCallback, useState, memo } from 'react'

import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, List, ListItemButton, ListItemText, TextField, Link } from '@mui/material'
import { GridColDef, GridCellParams } from '@mui/x-data-grid'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import dayjs from 'dayjs'
import * as _ from 'lodash'

import CustomerLogo, { CustomLogo } from 'core/components/CustomerLogo'
import CustomerProfile, { LocalCustomerType, FormValues } from 'core/components/CustomerProfile'
import CustomerForm from 'core/components/forms/customers'
import InfiniteScroll from 'core/components/InfiniteScroll'
import { Wide, Narrow } from 'core/components/layout'
import FloatingButton from 'core/components/molecules/FloatingButton'
import Notification from 'core/components/Notification'
import Sidepanel from 'core/components/Sidepanel'

import useModal from 'core/hooks/useModal'
import useNotification from 'core/hooks/useNotification'
import useQueryString from 'core/hooks/useQueryString'

import { addMissedProtocol, capitalize, getRidOfProtocol } from 'core/helpers/string'

import Button from 'mui/Button'
import DataGridLayout, { DataGrid, CellContent } from 'mui/DataGrid'
import Dialog from 'mui/Dialog'
import Skeleton from 'mui/Skeleton'

type CustomersListProps = {
  items?: LocalCustomerType[]
  loading: boolean
  saving: boolean
  count: number
  remove: UseMutateAsyncFunction<Response | undefined, unknown, LocalCustomerType['id'], unknown>
  edit: UseMutateAsyncFunction<
    LocalCustomerType | undefined,
    unknown,
    Pick<LocalCustomerType, 'id' | 'name' | 'domain' | 'deploymentUrl' | 'apiKey' | 'numberContributorsAllowed' | 'enabled'>,
    unknown
  >
  heading: ReactElement
}

type CustomerListItemProps = {
  isEven: boolean
  id: number
  name: string
  onSelect: (id: number) => void
}

type CellParamsType = GridCellParams<{ domain: string; id: string; selectRow: (id: string) => void }, string, string>

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'name',
    flex: 1,
    renderCell: (params: CellParamsType) => (
      <CellContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <CustomerLogo url={getRidOfProtocol(params.row.domain)} size="small" />
          <Link component="button" onClick={() => params.row.selectRow(params.row.id)} underline="none">
            {params.value}
          </Link>
        </Box>
      </CellContent>
    )
  },
  {
    field: 'domain',
    headerName: 'domain',
    flex: 1,
    sortable: false,
    renderCell: (params: CellParamsType) => (
      <CellContent>
        <Link href={addMissedProtocol(params.value || '')} underline="hover" target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      </CellContent>
    )
  },
  {
    field: 'deploymentUrl',
    headerName: 'deployment URL',
    flex: 1,
    sortable: false,
    renderCell: (params: CellParamsType) => (
      <CellContent>
        <Link href={addMissedProtocol(params.value || '')} underline="hover" target="_blank" rel="noopener noreferrer">
          {params.value}
        </Link>
      </CellContent>
    )
  },
  { field: 'enabled', headerName: 'enabled', flex: 1, sortable: false },
  { field: 'numberContributorsAllowed', headerName: 'contributors allowed', flex: 1 },
  { field: 'user', headerName: 'added by', flex: 1 },
  { field: 'addedDateTime', headerName: 'added date', flex: 1 },
  {
    field: 'customLogoImageData',
    headerName: 'custom logo',
    flex: 1,
    sortable: false,
    renderCell: (params: CellParamsType) => (params.value ? <CustomLogo imageData={params.value} /> : null)
  }
].map(({ headerName, ...keys }) => ({ ...keys, headerName: capitalize(headerName) }))

const CustomerListItem = memo((props: CustomerListItemProps) => {
  const { id, name, onSelect } = props

  const openProfile = () => {
    onSelect(id)
  }

  return (
    <Skeleton condition={!id} fullWidth>
      <ListItemButton onClick={openProfile}>
        <ListItemText primary={name} />
      </ListItemButton>
    </Skeleton>
  )
})

const AddCustomer = ({
  add,
  saving
}: {
  add: UseMutateAsyncFunction<
    Response | undefined,
    unknown,
    Pick<LocalCustomerType, 'name' | 'domain' | 'deploymentUrl' | 'apiKey' | 'numberContributorsAllowed' | 'enabled'>,
    unknown
  >
  saving: boolean
}) => {
  const { modalOpened: openedForm, openModal: openForm, closeModal: closeForm } = useModal<FormValues | null>(null)
  const { opened, status, message, openSuccess, openError, close } = useNotification()

  const handleAddSave = async (formValues: FormValues) => {
    try {
      await add({
        ...formValues,
        enabled: !!formValues.enabled
      })
      openSuccess(`Customer added successfully`)
      closeForm()
    } catch (err: any) {
      if (err.status == 409) {
        openError(err.error)
      }
    }
  }

  const openAddForm = () => {
    openForm({
      name: '',
      domain: '',
      deploymentUrl: '',
      apiKey: '',
      numberContributorsAllowed: 0,
      enabled: 1
    })
  }

  return (
    <>
      <Wide>
        <Button
          variant="outlined"
          onClick={openAddForm}
          color="primary"
          sx={{ height: '40px' }}
          startIcon={<FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 14 }} />}
        >
          add customer
        </Button>
      </Wide>
      <Narrow>
        <FloatingButton onClick={openAddForm} faIcon={faPlus} />
      </Narrow>

      <Sidepanel open={!!openedForm} onClose={closeForm}>
        <CustomerForm title={capitalize('add customer')} onClose={closeForm} save={handleAddSave} loading={saving} />
      </Sidepanel>
      <Notification open={opened} handleClose={close} severity={status} content={message} />
    </>
  )
}

export const Heading = ({ add, saving }: ComponentProps<typeof AddCustomer>) => {
  const { updateQueryParam } = useQueryString()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedUpdateQueryParam = _.debounce(updateQueryParam, 400)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: newSearchTerm } = event.target
    debouncedUpdateQueryParam({ searchTerm: newSearchTerm, page: 1 })
    setSearchTerm(newSearchTerm)
  }

  return (
    <>
      <Wide>
        <DataGridLayout.Heading title={capitalize('customers')}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: '10px' }}>
            <TextField label={capitalize('search')} sx={{ width: 300, height: '40px' }} value={searchTerm} onChange={handleChange} size="small" />
            <AddCustomer add={add} saving={saving} />
          </Box>
        </DataGridLayout.Heading>
      </Wide>
      <Narrow>
        <Box pb={1}>
          <DataGridLayout.Heading title={capitalize('customers')} />
        </Box>
        <TextField label={capitalize('search')} sx={{ width: '100%' }} onChange={handleChange} size="small" />
      </Narrow>
    </>
  )
}

const CustomersList = ({ items, loading, saving, count, remove, edit, heading }: CustomersListProps) => {
  const [rowSelectedId, setRowSelectedId] = useState<LocalCustomerType['id']>(-1)

  const { modalOpened: deleteDialogOpened, openModal: openDeleteDialog, closeModal: closeDeleteDialog } = useModal(-1)
  const { modalOpened: editDialogOpened, openModal: openEditDialog, closeModal: closeEditDialog } = useModal<FormValues | null>(null)
  const { modalOpened: openedForm, openModal: openForm, closeModal: closeForm } = useModal<FormValues | null>(null)

  const { opened, status, message, openSuccess, openError, close } = useNotification()

  const reformatDate = (addedDateTime: string) => {
    return dayjs(addedDateTime.substring(0, 10)).format('MM/DD/YYYY')
  }

  const selected = items
    ?.filter((item) => item.id === rowSelectedId)
    .map(({ addedDateTime, customLogo, ...customer }) => ({
      ...customer,
      addedDateTime: reformatDate(addedDateTime),
      customLogoImageData: customLogo?.imageData
    }))[0]

  const skeleton = {
    loading,
    size: 10
  }

  const handleRowSelect = useCallback((customerId: LocalCustomerType['id']) => {
    setRowSelectedId(customerId)
  }, [])

  const openSuccessSnackBar = (action: string) => {
    openSuccess(`Customer ${action} successfully`)
  }

  const closeProfile = () => {
    setRowSelectedId(-1)

    if (openedForm) {
      closeForm()
    }
  }

  const openEditForm = () => {
    if (selected) {
      const { name, domain, deploymentUrl, apiKey, numberContributorsAllowed, enabled } = selected
      openForm({
        name,
        domain,
        deploymentUrl,
        apiKey,
        numberContributorsAllowed,
        enabled: +enabled
      })
    }
  }

  const handleEditSaveClick = async (data: FormValues) => {
    openEditDialog(data)
  }

  const confirmEdit = async () => {
    if (rowSelectedId > -1 && editDialogOpened) {
      try {
        await edit({
          ...editDialogOpened,
          id: rowSelectedId,
          enabled: !!editDialogOpened.enabled
        })
        closeForm()
      } catch (err: any) {
        if (err.status === 409) {
          openError(err.error)
        }
      }
      closeEditDialog()
    }
  }

  const handleDeleteClick = () => {
    openDeleteDialog(rowSelectedId)
  }

  const confirmDelete = async () => {
    await remove(deleteDialogOpened)
    closeDeleteDialog()
    openSuccessSnackBar('deleted')
    closeProfile()
  }

  const rows = items
    ? items.map(({ id, name, domain, deploymentUrl, apiKey, numberContributorsAllowed, enabled, user, addedDateTime, customLogo }) => ({
        id,
        name,
        domain,
        deploymentUrl,
        apiKey,
        numberContributorsAllowed,
        enabled,
        user,
        addedDateTime: reformatDate(addedDateTime),
        customLogoImageData: customLogo?.imageData,
        selectRow: handleRowSelect
      }))
    : []

  return (
    <>
      <Wide>
        <DataGridLayout>
          {heading}
          <DataGrid rows={rows} columns={columns} count={count} loading={loading} />
        </DataGridLayout>
      </Wide>
      <Narrow>
        {heading}
        <InfiniteScroll dataLength={rows.length} count={count}>
          <List>
            {(skeleton.loading ? new Array(skeleton.size).fill({}) : rows).map((item, index) => (
              <CustomerListItem
                key={!skeleton.loading ? item.id : index}
                isEven={index % 2 === 0}
                onSelect={handleRowSelect}
                id={item.id}
                name={item.name}
              />
            ))}
          </List>
        </InfiniteScroll>
      </Narrow>

      <Sidepanel open={rowSelectedId > -1} onClose={closeProfile}>
        {openedForm ? (
          <CustomerForm
            title={capitalize('edit customer')}
            save={handleEditSaveClick}
            onClose={closeForm}
            loading={saving}
            {...(openedForm ? { initialValues: openedForm } : {})}
          />
        ) : (
          <CustomerProfile onEditClick={openEditForm} onDeleteClick={handleDeleteClick} {...selected} />
        )}
      </Sidepanel>

      <Dialog
        open={deleteDialogOpened > -1}
        title={capitalize('confirm delete')}
        content={capitalize('are you sure you want to delete this customer?')}
        onCancel={closeDeleteDialog}
        onBackdropClick={closeDeleteDialog}
        onOk={confirmDelete}
        loading={saving}
      />
      <Dialog
        open={!!editDialogOpened}
        title={capitalize('confirm changes')}
        content={capitalize('are you sure you want to change this customer?')}
        onCancel={closeEditDialog}
        onBackdropClick={closeEditDialog}
        onOk={confirmEdit}
        loading={saving}
      />
      <Notification open={opened} handleClose={close} severity={status} content={message} />
    </>
  )
}

CustomerListItem.displayName = 'CustomerListItem'
export default CustomersList
